select::-webkit-scrollbar {
    width: 10px;
    /* -webkit-box-shadow: inset 0 0 4px; */
}

select::-webkit-scrollbar-thumb {
    width: 15px;
    background: #666;
    border-radius: 5px;
    margin-right: 1px;
}

select::-webkit-scrollbar-thumb:hover {
    background: rgb(72, 72, 72);
}